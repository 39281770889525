<template>
  <div style="padding-bottom: .4rem">
    <img class="banner d-lg-block d-none" src="~assets/images/img/productBanner.jpg"/>
    <div class="productDetail">
      <div class="rightTitle">
        <div class="rightTitleValue d-lg-block d-flex align-items-center">
          <p class="rightTitleValueC">产品详情</p>
          <p class="rightTitleValueE">Product Details</p>
        </div>
        <div class="rightTitleLinks d-none d-lg-flex">
          <p>当前位置：</p>
          <router-link class="link" to="/home" tag="p">首页</router-link>
          >
          <!--刷新页面并且更新模块为第一块-->
          <p class="link" @click="$router.back(-1)">行业应用</p>
          >
          <p class="linkLast">产品详情</p>
        </div>
      </div>
      <p class="title" v-if="info">{{info.products[0].name}}</p>
      <div class="paramsList row" v-if="info">
        <div class="params col-lg-4 col-12">
          <div class="paramsRoundCoat ">
            <div class="paramsRound"></div>
          </div>
          <p class="paramsTitle">CAS：</p>
          <p class="paramsValue">{{info.products[0].CAS}}</p>
        </div>
        <div class="params col-lg-4 col-12" v-for="item in info.products_attribute">
          <div class="paramsRoundCoat ">
            <div class="paramsRound"></div>
          </div>
          <p class="paramsTitle">{{item.attributes_name}}：</p>
          <p class="paramsValue">{{item.attributes_nei}}</p>
        </div>
      </div>
      <p class="detailTitle">详细信息</p>
      <div class="detailInfo">
        <div class="detailInfoTitle">
          <div class="paramsRoundCoat detailTitleRoundCoat">
            <div class="paramsRound"></div>
          </div>
          <p class="detailInfoTitleText">特性</p>
        </div>
        <p class="detailInfoValue" v-if="info">{{info.products[0].use}}</p>
      </div>
      <div class="detailInfo">
        <div class="detailInfoTitle">
          <div class="paramsRoundCoat detailTitleRoundCoat">
            <div class="paramsRound"></div>
          </div>
          <p class="detailInfoTitleText">应用</p>
        </div>
        <p class="detailInfoValue" v-if="info">{{info.products[0].features}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {getProductDtl} from 'network/product';
  export default {
    name: "ProductDetail",
    created() {
      getProductDtl(this.$route.params.tid).then(res=>{
        this.info=res.data;
        console.log(this.info);
      })
    },
    data(){
      return {
        info:null,
        paramsList:[]
      }
    }
  }
</script>
  <style scoped>
    /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .paramsTitle{
      width: 4rem;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .paramsTitle{
      width: 2.4rem;
    }
  }


</style>


<style scoped>
  .banner {
    width: 100%;
    height: auto;
  }

  .productDetail {
    padding: 0 var(--marginX);
  }

  .rightTitle {
    padding: 1.5rem 0 .3rem 0;
    margin-bottom: .55rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }

  .title {
    padding-left: .6rem;
    border-left: 3px solid var(--red);
    font-size: .45rem;
    color: #4c4c4c;
    margin-bottom: .7rem;
  }
  .paramsList{
    margin-bottom: 1rem;
  }
  .params{
    display: flex;
    align-items: flex-start;
    padding-left: .6rem;
    margin-bottom: .6rem;
  }
  .paramsRoundCoat {
    width: 12px;
    height: 12px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: .3rem;
    position: relative;
    top:4px;
  }

  .paramsRound {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--red);
  }
  .paramsTitle{
    font-size: .4rem;
    color: #a6a6a6;
    /*width: 2.4rem;*/
  }
  .paramsValue{
    flex: 1;
    font-size: .4rem;
    color: #4c4c4c;
  }
  .detailTitle{
    padding: .5rem .7rem;
    font-size: .45rem;
    color: #fff;
    background-color: var(--red);
    margin-bottom: .6rem;
  }

  .detailInfo{
    padding-left: .6rem;
    margin-bottom: .6rem;
  }
  .detailTitleRoundCoat{
    top: 0;
  }
  .detailInfoTitle{
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
  }
  .detailInfoTitleText{
    font-size: .4rem;
    color: #333333;
    letter-spacing: 5px;
  }
  .detailInfoValue{
    font-size: .35rem;
    color: #a6a6a6;
  }
</style>